/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link, ListItem, Text, UnorderedList } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
// import config from '@config';
import { refineUrlDomain, refreshPage } from '@components/Utility/utils';

/* Services */

/**
 * Renders the Footer External Link  component
 *
 * @param {string} hlink - url
 * @param {string} text text
 * @returns {ReactElement} Footer External Link Items component
 */
const FooterExternalLinkItem = ({ hlink, text }) => (
  <ListItem mb={{ xl: '20px', base: '25px' }} pl="0" lineHeight="1.21">
    <Text
      as="span"
      borderBottom="0.3px solid #aea493"
      textDecor="none"
      paddingBottom="0.2vw"
      color="#aea493"
      fontSize="14px"
      lineHeight="1.21"
      _hover={{
        color: '#cd6727',
        borderBottom: '1px solid #cd6727',
        textDecoration: 'none',
      }}
    >
      <NextLink href={hlink} passHref prefetch={false} legacyBehavior>
        <Link
          onClick={e => refreshPage(e?.target?.href, e)}
          _hover={{
            textDecoration: 'none',
          }}
        >
          {text}
        </Link>
      </NextLink>
    </Text>
  </ListItem>
);

export const FooterExternalLinks = ({
  region,
  language,
  footerData,
  footerMenuData,
}) => (
  <Box
    width={{ base: '100%', lg: '50%', md: '100%' }}
    marginTop={{
      lg: '3rem',
    }}
    justifyContent="space-between"
    flexDir={{ base: 'row', lg: 'column', md: 'row' }}
    flexWrap="wrap"
  >
    <Box width={{ base: '100%', lg: '50%' }} float="left">
      <UnorderedList
        fontFamily="FedraSansStd-book,sans-serif"
        fontSize="14px"
        color="#aea493"
        listStyleType="none"
        // m="0"
        p="0 1.2rem 0 0"
        lineHeight="24px"
      >
        {footerMenuData[0]?.submenuItems.length > 0 &&
          footerMenuData[0]?.submenuItems?.map((externallink, index) => {
            return (
              <FooterExternalLinkItem
                key={index}
                hlink={refineUrlDomain(externallink?.url, region, language)}
                text={externallink?.title}
              />
            );
          })}
      </UnorderedList>
    </Box>
    <Box
      width={{ base: '100%', lg: '50%', md: '100%' }}
      float="left"
      marginBottom={{ base: '20px' }}
    >
      <UnorderedList
        fontFamily="FedraSansStd-book,sans-serif"
        fontSize="14px"
        color="#aea493"
        listStyleType="none"
        p="0 1.2rem 0 0"
        lineHeight="24px"
      >
        {footerMenuData[1]?.submenuItems.length > 0 &&
          footerMenuData[1]?.submenuItems?.map((externallink, index) => {
            return (
              <FooterExternalLinkItem
                key={index}
                hlink={refineUrlDomain(externallink?.url, region, language)}
                text={externallink?.title}
              />
            );
          })}
      </UnorderedList>
    </Box>
  </Box>
);
