/* Built In Imports */
/* External Imports */
import { Box, Heading, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import NextLink from 'next/link';

/* Internal Imports */
/* Components */
import { FooterExternalLinks } from '@components/NewFooter/FooterExternalLinks';
import { FooterSocialMediaLinks } from '@components/NewFooter/FooterSocialMediaLinks';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextContent from '@components/UI/StructuredTextContent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { refineUrlDomain, refreshPage } from '@components/Utility/utils';
import config from '@config';
import parser from 'html-react-parser';

/* Services */

/**
 * Renders the Footer component
 * @param {string} region - Country for constructing footer links.
 * @param {string} language Language for constructing footer links.
 * @param {object} footerData - Footer Data.
 * @param {object} pageConfig - pageConfig Data.
 * @param {object} page - page Data.
 * @returns {ReactElement} Footer component
 */
export default function Footer({
  region = 'in',
  language = 'en',
  footerData,
  pageConfig,
  page,
  ipWidgetData,
}) {
  // console.log("The footer data in home page", footerData)
  const pc_address = pageConfig?.footer_address;
  const pc_tnclink = pageConfig?.tnc;
  const pc_prvcypolicy = pageConfig?.privacy_policy;
  // const pc_instagram = pageConfig?.footer_instagram;
  // const pc_socialmedialink = pageConfig;
  let footerMenuData = [];
  if (footerData && footerData?.allIsoMainMenus?.length > 0) {
    footerMenuData = footerData?.allIsoMainMenus;
  }
  if (!ipWidgetData?.address) {
    ipWidgetData = null;
  }

  language = 'en';

  return (
    <>
      {footerData != '' && (
        <>
          <LazyLoadImageComponent
            src={`${config.imgPath}/d/46272/1663584078-footer-flying-yogi.jpg`}
            // bgImg="https://images.sadhguru.org/profiles/isha/themes/ishatheme/assets/Footer-Flying-Yogi.jpg"
            maxHeight="96px"
            height="96px"
            width="100%"
            bgSize="clip"
            objectFit="cover"
            bgPos="50%"
            display="block !important"
          />
          <Box
            as="footer"
            className="footer"
            id="footer"
            fontSize="14px"
            fontWeight="normal"
            line-height="1.36"
            color="#aea493"
            backgroundColor="#28231e"
            overflow="auto"
          >
            <Box
              display={{
                // base: 'block',
                lg: 'flex',
                // md: 'block',
                // sm: 'block'
              }}
              w="100%"
              margin="0 auto"
              backgroundColor="#28231e"
              maxWidth="1300px"
              p={{
                base: '2rem 1.5rem 0 1.5rem',
                md: '2rem 1.5rem 0 1.5rem',
                lg: '30px 15px',
              }}
            >
              <FooterExternalLinks
                region={region}
                language={language}
                footerMenuData={footerMenuData}
              />

              <LazyLoadImageComponent
                display={{
                  base: 'none !important',
                  lg: 'block !important',
                }}
                content="''"
                mt="0rem"
                width="24px"
                height="242px"
                src={`${config.imgPath}/d/46272/1663637617-flower-divider.png`}
                backgroundRepeat="no-repeat"
                backgroundSize="contain"
                transform="rotate(0deg)"
                left="10px"
                top="45px"
              />
              <Box
                width={{ lg: '25%', md: '100%', sm: '100%', base: '100%' }}
                ml={{
                  // base: '0',
                  lg: '4%',
                }}
                pl={{ base: '3%', md: '1%' }}
                mb="40px"
              >
                <Heading
                  as="h4"
                  fontSize="16px"
                  fontFamily="FedraSansStd-A-medium"
                  fontWeight="500"
                  fontStyle="normal"
                  color="#cd6727"
                  mb={{ base: '0rem', lg: '0.8rem' }}
                >
                  {footerMenuData[2]?.submenuItems[0]?.title}
                </Heading>
                <Box
                  fontSize="14px !important"
                  fontWeight="normal"
                  lineHeight="1.36"
                  color="#aea493"
                  fontFamily="FedraSansStd-book,sans-serif"
                  mb={{ base: '0.5rem' }}
                  display="inline-block"
                  width={'100%'}
                  display="flex"
                  flexDir={{ base: 'row', md: 'column' }}
                  gridGap={{ base: '20px', md: '0' }}
                >
                  <LazyLoadImageComponent
                    content="''"
                    mt="0rem"
                    maxW="109px"
                    src={`${footerMenuData[2]?.submenuItems[0]?.icon?.url}`}
                  />
                  <Box width="100%" maxW="153px" className="subs-text">
                    <StructuredTextParser
                      fontSize="12px"
                      m="0"
                      str={render(footerMenuData[2]?.submenuItems[1]?.body)}
                      region={region}
                      lang={language}
                    />
                    <style>
                      {`
                    .subs-text a {
                            padding: 10px;
                            border-radius: 3.15942px;
                            border: 1px solid #A29887;
                            display: flex;
                            align-items: center;
                            margin-top: 20px;
                      }

                      .subs-text a strong {
                        font-size: 14px;
                        margin: 0 auto;
                      }
                    `}
                    </style>
                  </Box>
                </Box>
              </Box>

              <Box
                width={{ lg: '25%', md: '100%', sm: '100%', base: '100%' }}
                ml={{
                  // base: '0',
                  lg: '1%',
                }}
                pl={{ base: '3%' }}
              >
                <Heading
                  as="h4"
                  fontSize="16px"
                  fontFamily="FedraSansStd-A-medium"
                  fontWeight="500"
                  fontStyle="normal"
                  color="#cd6727"
                  mb={{ base: '0rem', lg: '0.8rem' }}
                >
                  {footerMenuData[3]?.title}
                </Heading>
                <Box
                  fontSize="14px !important"
                  fontWeight="normal"
                  lineHeight="1.36"
                  color="#aea493"
                  fontFamily="FedraSansStd-book,sans-serif"
                  mb={{ base: '0.5rem' }}
                  display="inline-block"
                  width={'100%'}
                  className="contactblock"
                >
                  {pc_address == undefined ? (
                    <Box width="100%">
                      {ipWidgetData?.address?.address?.replace(/\n/g, '') && (
                        <Box
                          background={`url('${footerMenuData[3]?.submenuItems[0]?.icon?.url}')`}
                          backgroundRepeat="no-repeat"
                          backgroundPosition="0 3px"
                          style={{ textIndent: 18 }}
                        >
                          <Box mt="15px">
                            {parser(
                              ipWidgetData?.address?.address.replace(
                                /\n/g,
                                '<br>'
                              )
                            )}
                          </Box>
                        </Box>
                      )}
                      {!ipWidgetData && (
                        <Box
                          background={`url('${footerMenuData[3]?.submenuItems[0]?.icon?.url}')`}
                          backgroundRepeat="no-repeat"
                          backgroundPosition="0 3px"
                          style={{ textIndent: 18 }}
                        >
                          <StructuredTextParser
                            str={render(
                              ipWidgetData?.address?.address ||
                              footerMenuData[3]?.submenuItems[0]?.body.value
                            )}
                          />
                        </Box>
                      )}
                      {ipWidgetData?.address?.phone?.replace(/\n/g, '') && (
                        <Box
                          background={`url('${footerMenuData[3]?.submenuItems[1]?.icon?.url}')`}
                          backgroundRepeat="no-repeat"
                          backgroundPosition="0 4px"
                          style={{ textIndent: 18 }}
                          marginTop="0"
                        >
                          <Box mt="15px">
                            {parser(
                              ipWidgetData?.address?.phone.replace(
                                /\n/g,
                                '<br>'
                              )
                            )}
                          </Box>
                        </Box>
                      )}
                      {!ipWidgetData && (
                        <Box
                          background={`url('${footerMenuData[3]?.submenuItems[1]?.icon?.url}')`}
                          backgroundRepeat="no-repeat"
                          backgroundPosition="0 4px"
                          style={{ textIndent: 18 }}
                          marginTop="0"
                        >
                          <StructuredTextParser
                            str={render(
                              footerMenuData[3]?.submenuItems[1]?.body.value
                            )}
                          />
                        </Box>
                      )}
                      <Box
                        background={`url('${footerMenuData[3]?.submenuItems[2]?.icon?.url}')`}
                        backgroundRepeat="no-repeat"
                        backgroundPosition="0 7px"
                        style={{ textIndent: 18 }}
                      >
                        {ipWidgetData && (
                          <Box mt="15px">
                            <Link
                              href={`mailto:${ipWidgetData?.address?.email}`}
                            >
                              {ipWidgetData?.address?.email}
                            </Link>
                          </Box>
                        )}
                        {!ipWidgetData && (
                          <StructuredTextParser
                            str={render(
                              footerMenuData[3]?.submenuItems[2]?.body.value
                            )}
                          />
                        )}
                      </Box>
                      <Box mt="20px">
                        <NextLink
                          href={refineUrlDomain(
                            footerMenuData[3]?.submenuItems[3]?.url,
                            region,
                            language
                          )}
                          passHref
                          legacyBehavior
                        >
                          {footerMenuData[3]?.submenuItems[3]?.title}
                        </NextLink>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      width="100%"
                      dangerouslySetInnerHTML={{
                        __html: pc_address,
                      }}
                    ></Box>
                  )}
                </Box>
              </Box>

              <Box
                width={{ lg: '19%', md: '100%', sm: '100%' }}
                mt={{
                  base: '1rem',
                  lg: '0',
                  md: '1rem',
                }}
                pl={{ base: '3%', lg: '2%' }}
                minH={{ base: '150px' }}
              >
                <Heading
                  as="h4"
                  fontSize="16px"
                  fontFamily="FedraSansStd-A-medium"
                  fontWeight="500"
                  fontStyle="normal"
                  color="#cd6727"
                  mb={{ base: '1rem', lg: '1.9rem' }}
                >
                  {footerMenuData[4]?.title}
                </Heading>
                <Box>
                  <LazyLoadImageComponent
                    src={footerMenuData[4]?.submenuItems[0]?.icon?.url}
                    alt={footerMenuData[4]?.submenuItems[0]?.icon?.alt}
                    title={footerMenuData[4]?.submenuItems[0]?.icon?.title}
                    width="79px"
                    float={{ base: 'left', md: 'left', lg: 'none' }}
                  />
                  <Box
                    display="flex"
                    flexDir="column"
                    paddingTop={{
                      lg: '0.5rem',
                    }}
                    paddingLeft={{ base: '5%', lg: '0' }}
                  >
                    <Link
                      href={refineUrlDomain(
                        footerMenuData[4]?.submenuItems[1]?.url,
                        region,
                        language
                      )}
                      cursor="pointer"
                      mb={{ lg: '20px' }}
                      w="fit-content"
                    >
                      <LazyLoadImageComponent
                        src={footerMenuData[4]?.submenuItems[1]?.icon?.url}
                        alt={footerMenuData[4]?.submenuItems[1]?.icon?.alt}
                        title={footerMenuData[4]?.submenuItems[1]?.icon?.title}
                        width="126px"
                        height="auto"
                        p="0 0 10px 0"
                      />
                    </Link>
                    <Link
                      href={refineUrlDomain(
                        footerMenuData[4]?.submenuItems[2]?.url,
                        region,
                        language
                      )}
                      w="fit-content"
                    >
                      <LazyLoadImageComponent
                        src={footerMenuData[4]?.submenuItems[2]?.icon?.url}
                        alt={footerMenuData[4]?.submenuItems[2]?.icon?.alt}
                        title={footerMenuData[4]?.submenuItems[2]?.icon?.title}
                        width="126px"
                        height="auto"
                        p="0 0 15px 0"
                      />
                    </Link>
                  </Box>
                </Box>
              </Box>
              <Box
                pl={{ base: '3%' }}
                display="block"
                width={{ base: '100%', lg: '20%', md: '100%' }}
              >
                {page === 'Exclusive' && (
                  <Box id="exclusive-help-section" display="block">
                    <Box>Help &amp; Support</Box>
                    <Box fontSize="16px" mt="20px">
                      <Link
                        mb="40px"
                        href="https://exclusivesupport.sadhguru.org/"
                        display="block"
                        width="100px"
                      >
                        <LazyLoadImageComponent
                          src={`${config.imgPath}/d/46272/1663638011-help-button.svg`}
                          alt=""
                          title=""
                        />
                      </Link>
                    </Box>
                  </Box>
                )}
                <FooterSocialMediaLinks
                  region={region}
                  language={language}
                  footerMenuData={footerMenuData}
                  pc_socialmedialink={pageConfig}
                />
              </Box>
            </Box>
            <Box
              backgroundColor="#28231e"
              borderTop="1px solid #8a8173"
              p="1rem calc((100% - 1140px) / 2)"
              float="left"
              width="100%"
            >
              <Box
                fontSize="12px"
                fontFamily="FedraSansStd-medium"
                color="#676055"
                float="left"
                marginBottom={{
                  md: '1rem',
                }}
                paddingTop={{
                  md: '0.2rem',
                }}
                paddingRight={{
                  lg: '0',
                  md: '0',
                  sm: '2.25rem',
                }}
                pl={{ base: '1.5rem' }}
              // mr="4px"
              >
                {StructuredTextContent(
                  footerMenuData[6]?.submenuItems[0]?.body
                )}
              </Box>
              <Box
                float="left"
                marginBottom={{
                  md: '1rem',
                }}
                paddingTop={{
                  md: '0.2rem',
                }}
                paddingRight={{
                  lg: '0',
                  md: '0',
                  sm: '2.25rem',
                }}
                pl={{ base: '1.5rem', lg: '0.3rem' }}
              >
                <Text>
                  {'|'}
                  <Link
                    href={refineUrlDomain(
                      pc_tnclink == undefined
                        ? footerMenuData[6]?.submenuItems[1]?.url
                        : pc_tnclink,
                      region,
                      language
                    )}
                    fontFamily="FedraSansStd-medium"
                    fontSize="12px"
                    color="#676055"
                    pl="2px"
                    pr="5px"
                    ml={{ base: '5  px', sm: '5px', md: '5px', lg: '5px' }}
                    onClick={e => refreshPage(e?.target?.href)}
                    _hover={{ textDecoration: 'none' }}
                  >
                    {footerMenuData[6]?.submenuItems[1]?.title}
                  </Link>
                  {'|'}
                  <Link
                    href={refineUrlDomain(
                      pc_prvcypolicy == undefined
                        ? footerMenuData[6]?.submenuItems[2]?.url
                        : pc_prvcypolicy,
                      region,
                      language
                    )}
                    fontFamily="FedraSansStd-medium"
                    fontSize="12px"
                    color="#676055"
                    pl="2px"
                    ml="5px"
                    onClick={e => refreshPage(e?.target?.href)}
                    _hover={{ textDecoration: 'none' }}
                  >
                    {footerMenuData[6]?.submenuItems[2]?.title}
                  </Link>
                </Text>
              </Box>
            </Box>
            <style jsx global>{`
              .footer {
                clear: both;
              }
              .contactblock p {
                font-size: 14px !important;
              }
            `}</style>
          </Box>
        </>
      )}
    </>
  );
}
