/* Built In Imports */
import { useEffect, useRef, useState } from 'react';

/* External Imports */
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Collapse, Link } from '@chakra-ui/react';
import parser from 'html-react-parser';
import forEach from 'lodash/forEach';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import { refineUrlDomain } from '@msr/components/Utility/utils';

/* Services */

/**
 * Renders parsed string for structured text ShortCode.
 *
 * @param {string} str - HTML string.
 * @param {string} className - Class Name
 * @param {string} region - Region of the page
 * @param {string} textAlign - Alignment of the page
 * @returns {ReactElement} Structured Text Parser Code component
 */
const StructuredTextParser = ({
  str,
  mt,
  className,
  textAlign,
  region,
  lang,
  maxW,
  fontSize,
  fieldId,
  ...props
}) => {
  const allTags = ['readmore'];
  const [readMore, setReadMore] = useState(true);
  const structured = useRef();
  // const history = useHistory();
  if (textAlign && textAlign === 'center-align') {
    textAlign = 'center';
  }

  let innerText = '';
  let initialString = '';
  let startTagIndex = -1;

  // <a([^>]* )href=\"([^\"]+)\"
  const re = new RegExp('href="([^"]+)"', 'g');
  let strMatched = str && str.match(re);
  if (strMatched && strMatched.length) {
    forEach(strMatched, function (matched) {
      matched = matched.replace('href=', '').replace(/"/g, '');
      str = str.replace(
        `"${matched}"`,
        `"${refineUrlDomain(matched, region, lang)}"`
      );
    });
  }

  forEach(allTags, function (tag) {
    let matched = str && str.match(/\[(.*?)\]/g);
    if (matched && matched.length && matched[0].indexOf(tag) > -1) {
      startTagIndex = str.indexOf(matched[0]);
    }

    initialString = str?.substring(0, startTagIndex) || str;
    if (startTagIndex && startTagIndex > -1) {
      let endTagStartIndex = str.indexOf(`[/${tag}]`);
      let endTagEndIndex = endTagStartIndex + tag.length + 3;
      let tagTextString = str?.substring(startTagIndex, endTagEndIndex);
      innerText = tagTextString
        .replace(`[${tag}]`, '')
        .replace(`[/${tag}]`, '')
        .replace(matched[0], '');
    }
  });

  // Function to extract attributes from the given tag in the parser body received from API.
  /**
   *
   * @param tagWithAttributes
   */
  function getAttributes(tagWithAttributes) {
    let attributesArray = [];

    let attributes = tagWithAttributes.match(
      /[\w-]+="[^"]*"|[\w-]+=[^\s|\]]*/g
    );
    if (attributes !== null && attributes.length > 0) {
      attributes.map(att => {
        let separateAttribute = att?.split('=');
        attributesArray[separateAttribute[0]] = separateAttribute[1].replace(
          /['"]+/g,
          ''
        );
      });
    }
    return attributesArray;
  }

  useEffect(
    () => () => {
      setReadMore(true);
    },
    [str]
  );

  return (
    <Box ref={structured}>
      {fieldId === 'msr-editore-note' && (
        <Box display="flex" placeContent="center" mt="20px" mb="43px">
          <LazyLoadImageComponent
            src={`${config.imgPath}/d/46272/1668597237-blue-lotus-vector.png`}
          />
        </Box>
      )}
      <Box
        className={
          className || props?.bgTheme === 'light'
            ? 'structured-content-light'
            : 'structured-content-dark'
        }
        id={fieldId}
        m={{ base: '0 auto' }}
        mt={{ base: mt || '15px', md: mt || '15px', lg: mt || '15px' }}
        maxW={maxW || '586px'}
        textAlign={textAlign || 'left'}
        fontSize={fontSize || '1rem'}
        color={
          textAlign === 'center' || textAlign === 'center-align'
            ? '#FFF7E9'
            : '#C1C3DD'
        }
        px={{ base: 2, md: '' }}
        {...props}
      >
        {initialString && parser(initialString)}
      </Box>
      {startTagIndex > -1 && (
        <Box
          mx="auto"
          mt={{ base: mt || '10px', md: mt || '30px', lg: mt || '30px' }}
          maxW={{
            base: '100%',
            md: '515px',
            lg: '600px',
            xl: '770px',
          }}
        >
          <Collapse in={!readMore} animateOpacity duration={20000}>
            <Box
              fontFamily="FedraSansStd-book"
              pb="30px"
              className={`${className || ''}`}
              textAlign={textAlign || 'left'}
            >
              {parser(innerText)}
            </Box>
          </Collapse>
          <Box textAlign={'center'} position="relative">
            <Link
              display="inline-block"
              mb="20px"
              fontFamily="FedraSansStd-medium"
              borderRadius="3px"
              bgColor="transparent"
              border="solid 1px #cf4520 !important"
              color={'#cf4520 !important'}
              fontSize="18px !important"
              padding="10px 35px"
              textDecoration="none !important"
              transition="none !important"
              _hover={{
                textDecoration: 'none',
                bgColor: '#cf4520',
                color: 'white !important',
              }}
              textAlign="center"
              width={'200px'}
              onClick={() => setReadMore(!readMore ? true : false)}
            >
              Read {readMore ? 'More' : 'Less'}
              {readMore ? (
                <ChevronDownIcon w={7} h={10} />
              ) : (
                <ChevronUpIcon w={7} h={10} />
              )}
            </Link>
          </Box>
        </Box>
      )}
      <style global jsx>
        {`
          .structured-content-dark #msr-question,
          .structured-content-dark #msr-sharing {
            margin: 0 !important;
            padding: 0 !important;
          }
          .structured-content-dark h2 {
            font-size: 35px;
          }
          .structured-content-dark #msr-editore-note,
          .structured-content-dark #msr-sharing {
            margin-left: 0 !important;
          }
          .structured-content-dark #msr-editore-note strong {
            color: #dc8513 !important;
            font-size: 16px !important;
            border-bottom: 1px solid #dc8513 !important;
          }
          .structured-content-dark #msr-sharings p:nth-last-child(1) {
            margin-top: 0 !important;
          }
        `}
      </style>
    </Box>
  );
};

export default StructuredTextParser;
